<template>
  <section>
    <div class="content-header">
      <h2>Create your own positionality statement </h2>
    </div>
    <div class="content-wrapper">
      <p>Ready to start crafting your own positionality statement? Here’s what you might include.</p>
      <div class="accordion">
              <accordion
                headerText="Context"
                accordionID="collapse01"
              >
                <p>Why are you sharing your positionality? </p>
                <p><strong>Examples</strong></p>
                <ul>
                  <li class="pb-2">“We will be discussing issues of x or y, and it’s important you understand what’s informed my perspective on this.” </li>
                  <li>“I want you to know more about me and who I am, and to feel comfortable sharing the same about yourself, so we can have a learning community grounded in authentic relationships. Therefore, I’d like to share more about myself.”</li>
                </ul>
              </accordion>
              <accordion
                headerText="Information about yourself"
                accordionID="collapse02"
              >
                <p>Are there aspects of your identities where you’ve experienced privilege and/or marginalization? In what contexts were these experiences most salient to you? What feels most relevant to how you show up in the classroom?</p>
                <p><strong>Examples</strong></p>
                <p>You might share your sexual orientation, gender, racial/ethnic identity, socioeconomic status, developmental experiences, lived experience in education or career settings, mental health status and/or experience with disability.</p>
              </accordion>
              <accordion
                headerText="Impacts of your experiences"
                accordionID="collapse03"
              >
                <p>How have your experiences and who you are influenced your worldview, your perspectives on subjects you’ll be discussing or your approach as an instructor or educator?</p>
                <p><strong>Examples</strong></p>
                <ul>
                  <li class="pb-2">Describe how your identities and experiences have influenced what you’ve studied, your research, your teaching focus or how you approach your work. </li>
                  <li>Talk about how your identities and experiences have influenced your teaching philosophy and the learning community you’d like to create.</li>
                </ul>
              </accordion>
              <accordion
                headerText="Willingness to learn from others"
                accordionID="collapse04"
              >
                <p>When you’re sharing your positionality, express humility and a curiosity to learn from others who have identities and lived experiences different from your own. </p>
                <p>All of us will hold an intersection of privileged and oppressed identities. </p>
                <p><strong>Examples</strong></p>
                <p>Part of a positionality statement should include:</p>
                <ul>
                  <li class="pb-2">An invitation for others to share</li>
                  <li>Your genuine desire to learn from students/others who have identities and experiences that don’t align with yours</li>
                </ul>
              </accordion>
            </div>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
